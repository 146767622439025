<template>
    <van-form
        ref="form"
        error-message-align="right"
        input-align="right"
        :readonly="readonly"
        @submit="onSubmit"
    >
        <van-cell-group inset :border="false">
            <van-field
                required
                readonly
                is-link
                label="实施日期"
                placeholder="点击选择日期"
                :value="dayjs(execDate).format('YYYY-MM-DD')"
                :rules="rules.execDate"
                @click="readonly ? '' : (showCalendar = true)"
            />
            <van-calendar
                v-model="showCalendar"
                :min-date="minDate"
                :max-date="maxDate"
                color="var(--mainColor)"
                @confirm="onConfirm"
            />

            <van-field
                :value="execPlace"
                :rules="rules.execPlace"
                readonly
                is-link
                required
                label="实施地址"
                @click="readonly ? '' : $router.push(`/hoss/txMap?storageKey=${mapStorageKey}`)"
            />

            <van-field
                v-model="execDetail"
                class="my-textarea"
                required
                label="总结"
                placeholder="请点击输入文本内容"
                type="textarea"
                maxlength="300"
                show-word-limit
                :rules="rules.execDetail"
                :autosize="{ maxHeight: 300, minHeight: 100 }"
            />

            <van-field class="upload-field" label="图片和视频">
                <template #input>
                    <my-uploader :disabled="readonly" v-model="pictures" />
                </template>
            </van-field>
        </van-cell-group>
        <div class="btn-group">
            <van-button color="var(--secondMainColor)" native-type="button" @click="goBack">
                返回
            </van-button>
            <van-button
                v-if="!readonly"
                color="var(--mainColor)"
                native-type="submit"
                :loading="submitting"
            >
                提交
            </van-button>
        </div>
    </van-form>
</template>

<script>
import { saveIceRes, queryIceRes } from "@/api/hoss";
import MyUploader from "@/components/MyUploader";
import dayjs from "dayjs";
export default {
    components: { MyUploader },
    data() {
        return {
            readonly: false,

            execDate: +new Date(),
            execDetail: "",
            execPlace: "",
            pictures: "",

            rules: {
                execDate: [{ required: true, message: "请选择实施日期" }],
                execDetail: [{ required: true, message: "请填写总结" }],
                execPlace: [{ required: true, message: "点击选择实施地址" }],
            },

            showCalendar: false,
            minDate: dayjs().subtract(1, "month").toDate(),
            maxDate: new Date(),

            submitting: false,
            mapStorageKey: "hoss-position",
        };
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            if (from.name == "TxMap") {
                let location = "";
                const data = sessionStorage.getItem(vm.mapStorageKey);
                if (data) {
                    const { ad_info, title } = JSON.parse(data);
                    location = `${ad_info.city}·${title}`;
                    sessionStorage.removeItem(vm.mapStorageKey);
                }
                if (location) vm.execPlace = location;
            } else {
                vm.init();
            }
        });
    },
    methods: {
        dayjs,
        async init() {
            console.log("init");
            const { id, clientId } = this.$route.query;
            this.clientId = clientId;

            if (id) {
                const { value } = await queryIceRes(id);
                const { execDate, execDetail, execPlace, pictures, status } = value;
                this.execDate = execDate;
                this.execDetail = execDetail;
                this.execPlace = execPlace;
                this.pictures = pictures;
                this.readonly = status;
            } else {
                this.execDate = +new Date();
                this.execDetail = "";
                this.execPlace = "";
                this.pictures = "";
                this.readonly = false;
            }
        },
        async onSubmit() {
            this.submitting = true;
            console.log("submit");
            try {
                const { execDate, execDetail, execPlace, pictures, clientId } = this;
                await saveIceRes({ execDate, execDetail, execPlace, pictures, clientId });
                this.goBack();
            } catch (error) {}
            this.submitting = false;
        },
        goBack() {
            this.$router.back(-1);
        },
        onConfirm(date) {
            this.execDate = +date;
            this.showCalendar = false;
        },
    },
};
</script>

<style lang="less" scoped>
.upload-field.van-field {
    display: block;
    /deep/.van-field__label {
        width: 100%;
        &::after {
            content: "（如上传视频控制在1分钟内）";
            color: var(--subTitleColor);
            font-size: 12px;
        }
    }
    /deep/.van-field__control--right {
        margin-top: 5px;
        justify-content: flex-start;
    }
}

.van-form {
    margin-bottom: 54px;
    .van-cell-group {
        margin: 10px;
        border-radius: 10px;
    }
    .btn-group {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        display: flex;
        .van-button {
            flex: 1 0 50%;
            border-radius: 0;
        }
    }
}
</style>
